import React, { useEffect, useState } from 'react';
import {
    AppBar,
    Toolbar,
    Box,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ThemeProvider,
    IconButton,
    Avatar,
    Menu,
    MenuItem,
    Divider,
    CssBaseline,
} from '@mui/material';
import { useNavigate, Link, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout as logoutAction } from '../slices/authSlice';
import AccountCircle from '@mui/icons-material/AccountCircle';
import TranslateIcon from '@mui/icons-material/Translate';
import FolderIcon from '@mui/icons-material/Folder';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import GroupIcon from '@mui/icons-material/Group';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NotificationsIcon from '@mui/icons-material/Notifications';
import theme from '../theme';
import axios from 'axios';
//import jwtDecode from 'jwt-decode';


function TranslationDashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const [profileData, setProfileData] = useState({});
    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    //const decodedToken = token ? jwtDecode(token) : null;
    //const userId = decodedToken?.id;


    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await axios.get('/api/users/me', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                console.log('Fetched profile data:', response.data); // Debug log
                setProfileData(response.data);
            } catch (err) {
                console.error('Error fetching profile data:', err);
            }
        };

        fetchProfileData();
    }, [token]);


    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(logoutAction());  // Dispatch logout action to update auth state
        localStorage.removeItem('token');  // Remove token from local storage
        handleMenuClose();  // Close the menu if still open
        navigate('/');  // Navigate to homepage
    };


    const menuItems = [
        { text: 'Dashboard', icon: <HomeIcon />, path: '' },
        { text: 'Translation Projects', icon: <FolderIcon />, path: 'projects' },
        { text: 'Start New Translation', icon: <TranslateIcon />, path: 'new-translation' },
        { text: 'Language Settings', icon: <SettingsIcon />, path: 'language-settings' },
        { text: 'Team', icon: <GroupIcon />, path: 'team' },
        { text: 'Account Settings', icon: <AccountCircle />, path: 'account-settings' },
    ];

    const toggleSidebar = () => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
    };

    const getInitials = (name) => {
        const [firstName, lastName] = name.split(' ');
        return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />

                {/* Header Bar */}
                <AppBar
                    position="fixed"
                    color="default"
                    elevation={1}
                    sx={{
                        zIndex: theme.zIndex.drawer + 1,
                        backgroundColor: '#f8f9fb',
                        borderBottom: '1px solid #e0e0e0',
                        padding: '0 24px',
                    }}
                >
                    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        {/* Logo */}
                        <Box sx={{ display: 'flex', alignItems: 'center', width: isSidebarCollapsed ? 60 : 240, padding: '8px 16px' }}>
                            <img src="/assets/logo/logo.png" alt="Logo" style={{ height: 40 }} />
                        </Box>

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* Notification Icon */}
                            <IconButton color="inherit">
                                <NotificationsIcon />
                            </IconButton>

                            {/* Profile Avatar */}
                            <IconButton color="inherit" onClick={handleMenuClick}>
                                {profileData.avatarUrl ? (
                                    <Avatar src={`/api/users/avatars/${profileData._id}/${profileData.avatarUrl.split('/').pop()}`} alt={profileData.firstName || 'User'} />
                                ) : profileData.firstName || profileData.lastName ? (
                                    <Avatar>{getInitials(`${profileData.firstName} ${profileData.lastName}`)}</Avatar>
                                ) : (
                                    <AccountCircle />
                                )}
                            </IconButton>

                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleMenuClose}
                                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            >
                                <MenuItem onClick={() => { navigate('/dashboard/profile'); handleMenuClose(); }}>
                                    Profile
                                </MenuItem>
                                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                            </Menu>
                        </Box>
                    </Toolbar>
                </AppBar>

                {/* Sidebar Drawer */}
                <Drawer
                    variant="permanent"
                    sx={{
                        width: isSidebarCollapsed ? 60 : 240,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: isSidebarCollapsed ? 60 : 240,
                            boxSizing: 'border-box',
                            backgroundColor: '#f0f4ff',
                            color: theme.palette.text.primary,
                        },
                    }}
                >
                    <Toolbar /> {/* Spacer for header */}
                    <IconButton
                        color="inherit"
                        onClick={toggleSidebar}
                        sx={{
                            alignSelf: 'center',
                            marginTop: 1,
                            '&:hover': { backgroundColor: 'transparent' },
                        }}
                    >
                        {isSidebarCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                    <List>
                        {menuItems.map((item) => (
                            <ListItem button key={item.text} component={Link} to={`/dashboard/${item.path}`}>
                                <ListItemIcon sx={{ color: theme.palette.text.primary }}>{item.icon}</ListItemIcon>
                                {!isSidebarCollapsed && <ListItemText primary={item.text} />}
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                </Drawer>

                {/* Main Content Area */}
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                    <Toolbar /> {/* Spacer for header */}
                    <Outlet />
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default TranslationDashboard;

import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, IconButton, Modal } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import { useSelector } from 'react-redux';

const AnnotationPanel = ({ projectId, sectionIndex, open, onClose, onAnnotationAdded }) => {
    const [annotations, setAnnotations] = useState([]);
    const [newComment, setNewComment] = useState('');
    const token = useSelector((state) => state.auth.token);

    // Fetch annotations for the section
    useEffect(() => {
        if (open) {
            const fetchAnnotations = async () => {
                try {
                    const response = await axios.get(`/api/annotations/${projectId}/annotations`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    setAnnotations(response.data.filter(annotation => annotation.sectionIndex === sectionIndex));
                } catch (error) {
                    console.error("Error fetching annotations:", error);
                }
            };
            fetchAnnotations();
        }
    }, [projectId, sectionIndex, open, token]);

    // Handle adding a new comment
    const handleAddAnnotation = async () => {
        if (!newComment.trim()) return;
        try {
            const response = await axios.post(
                `/api/annotations/${projectId}/annotations`,
                { sectionIndex, comment: newComment },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setAnnotations([...annotations, response.data]);
            setNewComment('');

            // Notify parent of new annotation
            onAnnotationAdded(sectionIndex); // Call the callback with sectionIndex
        } catch (error) {
            console.error("Error adding annotation:", error);
        }
    };

    // Handle deleting an annotation
    const handleDeleteAnnotation = async (annotationId) => {
        try {
            await axios.delete(`/api/annotations/annotations/${annotationId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setAnnotations(annotations.filter(annotation => annotation._id !== annotationId));
        } catch (error) {
            console.error("Error deleting annotation:", error);
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 3,
                    borderRadius: 2,
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                    <Typography variant="h6">Annotations</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <TextField
                    fullWidth
                    multiline
                    rows={3}
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder="Add a comment"
                    variant="outlined"
                    sx={{ marginBottom: 2 }}
                />
                <Button
                    variant="contained"
                    fullWidth
                    onClick={handleAddAnnotation}
                    disabled={!newComment.trim()}
                >
                    Add Comment
                </Button>

                <Box sx={{ marginTop: 2, maxHeight: '250px', overflowY: 'auto' }}>
                    {annotations.map(annotation => {
                        const authorName = annotation.author.firstName && annotation.author.lastName
                            ? `${annotation.author.firstName} ${annotation.author.lastName}`
                            : annotation.author.email; // Fallback to email if name is missing

                        return (
                            <Box
                                key={annotation._id}
                                sx={{
                                    marginTop: 2,
                                    paddingBottom: 1,
                                    borderBottom: '1px solid #eee',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <Box>
                                    <Typography variant="body2" color="textSecondary">
                                        {authorName}:
                                    </Typography>
                                    <Typography variant="body1">{annotation.comment}</Typography>
                                </Box>
                                <IconButton onClick={() => handleDeleteAnnotation(annotation._id)} size="small" color="secondary">
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Box>
                        );
                    })}
                </Box>
            </Box>
        </Modal>
    );
};

export default AnnotationPanel;

import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../slices/authSlice';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import LoginIcon from '@mui/icons-material/Login';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0,
    borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
    backdropFilter: 'blur(24px)',
    border: '1px solid',
    borderColor: theme.palette.divider,
    backgroundColor: alpha(theme.palette.background.default, 0.4),
    boxShadow: theme.shadows[1],
    padding: '8px 12px',
}));

export default function AppAppBar() {
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector((state) => state.auth.token);
    const [isAuthenticated, setIsAuthenticated] = React.useState(false);

    React.useEffect(() => {
        const storedToken = localStorage.getItem('token');
        setIsAuthenticated(Boolean(token || storedToken));
    }, [token]);

    const handleRegister = () => navigate('/register');
    const handleLogin = () => navigate('/login');
    const handleLogout = () => {
        dispatch(logout());
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        navigate('/');
    };
    const handleDashboard = () => navigate('/dashboard');
    const toggleDrawer = (newOpen) => () => setOpen(newOpen);

    return (
        <AppBar position="fixed" sx={{ boxShadow: 0, bgcolor: 'transparent', backgroundImage: 'none', mt: 10 }}>
            <Container maxWidth="lg">
                <StyledToolbar variant="dense" disableGutters>
                    {/* Logo Section with Image */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img
                            src="/assets/logo/logo.png"
                            alt="Logo"
                            style={{ height: '40px', width: 'auto' }}
                        />
                    </Box>

                    {/* Navigation Links */}
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, ml: 2 }}>
                        <Button variant="text" color="info" size="small">Features</Button>
                        <Button variant="text" color="info" size="small">Testimonials</Button>
                        <Button variant="text" color="info" size="small">Highlights</Button>
                        <Button variant="text" color="info" size="small">Pricing</Button>
                        <Button variant="text" color="info" size="small" sx={{ minWidth: 0 }}>FAQ</Button>
                        <Button variant="text" color="info" size="small" sx={{ minWidth: 0 }}>Blog</Button>
                    </Box>

                    {/* Authentication Buttons */}
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, gap: 1, alignItems: 'center' }}>
                        {isAuthenticated ? (
                            <>
                                <Button color="primary" variant="text" size="small" startIcon={<AccountCircleIcon />} onClick={handleDashboard}>
                                    Dashboard
                                </Button>
                                <Button color="primary" variant="text" size="small" startIcon={<LoginIcon />} onClick={handleLogout}>
                                    Logout
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button color="primary" variant="text" size="small" startIcon={<LoginIcon />} onClick={handleLogin}>
                                    Sign in
                                </Button>
                                <Button color="primary" variant="contained" size="small" startIcon={<PersonAddIcon />} onClick={handleRegister}>
                                    Sign up
                                </Button>
                            </>
                        )}
                    </Box>

                    {/* Drawer for mobile view */}
                    <Box sx={{ display: { sm: 'flex', md: 'none' } }}>
                        <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
                            <MenuIcon />
                        </IconButton>
                        <Drawer anchor="top" open={open} onClose={toggleDrawer(false)}>
                            <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <IconButton onClick={toggleDrawer(false)}>
                                        <CloseRoundedIcon />
                                    </IconButton>
                                </Box>
                                <Divider sx={{ my: 3 }} />
                                <MenuItem>Features</MenuItem>
                                <MenuItem>Testimonials</MenuItem>
                                <MenuItem>Highlights</MenuItem>
                                <MenuItem>Pricing</MenuItem>
                                <MenuItem>FAQ</MenuItem>
                                <MenuItem>Blog</MenuItem>
                                {isAuthenticated ? (
                                    <>
                                        <MenuItem>
                                            <Button color="primary" variant="text" fullWidth startIcon={<AccountCircleIcon />} onClick={handleDashboard}>
                                                Dashboard
                                            </Button>
                                        </MenuItem>
                                        <MenuItem>
                                            <Button color="primary" variant="outlined" fullWidth startIcon={<LoginIcon />} onClick={handleLogout}>
                                                Logout
                                            </Button>
                                        </MenuItem>
                                    </>
                                ) : (
                                    <>
                                        <MenuItem>
                                            <Button color="primary" variant="contained" fullWidth startIcon={<PersonAddIcon />} onClick={handleRegister}>
                                                Sign up
                                            </Button>
                                        </MenuItem>
                                        <MenuItem>
                                            <Button color="primary" variant="outlined" fullWidth startIcon={<LoginIcon />} onClick={handleLogin}>
                                                Sign in
                                            </Button>
                                        </MenuItem>
                                    </>
                                )}
                            </Box>
                        </Drawer>
                    </Box>
                </StyledToolbar>
            </Container>
        </AppBar>
    );
}

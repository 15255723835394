import { createSlice } from '@reduxjs/toolkit';

// Define initial state
const initialState = {
    token: null,
    isAuthenticated: false,
    user: null,
};

// Create the auth slice
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        // Action to handle login success
        loginSuccess: (state, action) => {
            state.token = action.payload.token;
            state.isAuthenticated = true;
            state.user = action.payload.user;

            // Save the token to localStorage
            localStorage.setItem('token', action.payload.token);
        },

        // Action to handle logout
        logout: (state) => {
            state.token = null;
            state.isAuthenticated = false;
            state.user = null;

            // Remove the token from localStorage
            localStorage.removeItem('token');
        },
    },
});

// Export actions
export const { loginSuccess, logout } = authSlice.actions;

// Export the reducer
export default authSlice.reducer;


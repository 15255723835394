import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Box, TextField, Button, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const EditProject = () => {
    const { id } = useParams();  // Get project ID from the URL
    const [project, setProject] = useState(null);
    const [projectName, setProjectName] = useState('');
    const [sourceLanguage, setSourceLanguage] = useState('');
    const [targetLanguage, setTargetLanguage] = useState('');
    const [deadline, setDeadline] = useState('');
    const [error, setError] = useState('');
    const token = useSelector((state) => state.auth.token);
    const navigate = useNavigate();

    // Language options (can be replaced with an API call)
    const languageOptions = ['English', 'Spanish', 'French', 'German', 'Chinese', 'Japanese'];

    useEffect(() => {
        // Fetch the project details for editing
        const fetchProject = async () => {
            try {
                const response = await axios.get(`http://192.168.68.130:5002/projects/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const projectData = response.data;
                setProject(projectData);
                setProjectName(projectData.projectName);
                setSourceLanguage(projectData.sourceLanguage);
                setTargetLanguage(projectData.targetLanguage);
                setDeadline(projectData.deadline ? projectData.deadline.split('T')[0] : '');  // Format deadline
            } catch (err) {
                console.error('Error fetching project:', err);
                setError('Error fetching project details.');
            }
        };

        fetchProject();
    }, [id, token]);

    // Handle saving changes to the project
    const handleSave = async () => {
        if (!projectName || !sourceLanguage || !targetLanguage) {
            setError('Please fill out all required fields.');
            return;
        }

        try {
            await axios.put(`http://192.168.68.130:5002/projects/${id}`, {
                projectName,
                sourceLanguage,
                targetLanguage,
                deadline,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            navigate('/projects');  // Redirect back to the projects list after saving
        } catch (err) {
            console.error('Error saving project:', err);
            setError('Error saving project.');
        }
    };

    if (!project) return <Typography>Loading...</Typography>;

    return (
        <Container maxWidth="md" sx={{ marginTop: '50px' }}>
            <Paper elevation={3} sx={{ padding: '30px', borderRadius: '10px' }}>
                <Typography variant="h4" gutterBottom>Edit Project</Typography>

                {/* Project Name */}
                <Box sx={{ marginBottom: '20px' }}>
                    <TextField
                        label="Project Name"
                        variant="outlined"
                        fullWidth
                        required
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                    />
                </Box>

                {/* Source Language */}
                <Box sx={{ marginBottom: '20px' }}>
                    <FormControl fullWidth>
                        <InputLabel>Source Language</InputLabel>
                        <Select
                            value={sourceLanguage}
                            onChange={(e) => setSourceLanguage(e.target.value)}
                            required
                        >
                            {languageOptions.map((language) => (
                                <MenuItem key={language} value={language}>
                                    {language}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {/* Target Language */}
                <Box sx={{ marginBottom: '20px' }}>
                    <FormControl fullWidth>
                        <InputLabel>Target Language</InputLabel>
                        <Select
                            value={targetLanguage}
                            onChange={(e) => setTargetLanguage(e.target.value)}
                            required
                        >
                            {languageOptions.map((language) => (
                                <MenuItem key={language} value={language}>
                                    {language}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {/* Deadline */}
                <Box sx={{ marginBottom: '20px' }}>
                    <TextField
                        label="Deadline"
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        value={deadline}
                        onChange={(e) => setDeadline(e.target.value)}
                    />
                </Box>

                {error && <Typography color="error">{error}</Typography>}

                <Button variant="contained" color="primary" onClick={handleSave}>
                    Save Changes
                </Button>
            </Paper>
        </Container>
    );
};

export default EditProject;

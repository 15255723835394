// isoLanguages.js
const isoLanguages = [
    { code: 'en-gb', name: 'English (British)', countryCode: 'GB' },
    { code: 'en-us', name: 'English (American)', countryCode: 'US' },
    { code: 'es', name: 'Spanish', countryCode: 'ES' },
    { code: 'fr', name: 'French', countryCode: 'FR' },
    { code: 'de', name: 'German', countryCode: 'DE' },
    { code: 'zh', name: 'Chinese', countryCode: 'CN' },
    { code: 'ja', name: 'Japanese', countryCode: 'JP' },
    { code: 'it', name: 'Italian', countryCode: 'IT' },
    { code: 'ko', name: 'Korean', countryCode: 'KR' },
    { code: 'pt', name: 'Portuguese', countryCode: 'PT' },
    { code: 'ru', name: 'Russian', countryCode: 'RU' },
    { code: 'ar', name: 'Arabic', countryCode: 'SA' },
    { code: 'hi', name: 'Hindi', countryCode: 'IN' },
    { code: 'bn', name: 'Bengali', countryCode: 'BD' },
    { code: 'nl', name: 'Dutch', countryCode: 'NL' },
    { code: 'sv', name: 'Swedish', countryCode: 'SE' },
    { code: 'no', name: 'Norwegian', countryCode: 'NO' },
    { code: 'fi', name: 'Finnish', countryCode: 'FI' },
    { code: 'da', name: 'Danish', countryCode: 'DK' },
    { code: 'pl', name: 'Polish', countryCode: 'PL' },
    { code: 'tr', name: 'Turkish', countryCode: 'TR' },
    { code: 'th', name: 'Thai', countryCode: 'TH' },
    { code: 'vi', name: 'Vietnamese', countryCode: 'VN' },
    { code: 'el', name: 'Greek', countryCode: 'GR' },
    { code: 'he', name: 'Hebrew', countryCode: 'IL' },
    { code: 'id', name: 'Indonesian', countryCode: 'ID' },
    { code: 'ms', name: 'Malay', countryCode: 'MY' },
    { code: 'cs', name: 'Czech', countryCode: 'CZ' },
    { code: 'hu', name: 'Hungarian', countryCode: 'HU' },
    { code: 'ro', name: 'Romanian', countryCode: 'RO' },
    { code: 'sk', name: 'Slovak', countryCode: 'SK' },
    { code: 'uk', name: 'Ukrainian', countryCode: 'UA' },
    { code: 'bg', name: 'Bulgarian', countryCode: 'BG' },
    { code: 'hr', name: 'Croatian', countryCode: 'HR' },
    { code: 'sr', name: 'Serbian', countryCode: 'RS' },
    { code: 'sl', name: 'Slovenian', countryCode: 'SI' },
    { code: 'lt', name: 'Lithuanian', countryCode: 'LT' },
    { code: 'lv', name: 'Latvian', countryCode: 'LV' },
    { code: 'et', name: 'Estonian', countryCode: 'EE' },
    { code: 'fa', name: 'Persian', countryCode: 'IR' },
    { code: 'sw', name: 'Swahili', countryCode: 'KE' },
    { code: 'am', name: 'Amharic', countryCode: 'ET' },
    { code: 'yo', name: 'Yoruba', countryCode: 'NG' },
    { code: 'zu', name: 'Zulu', countryCode: 'ZA' },
    { code: 'xh', name: 'Xhosa', countryCode: 'ZA' },
    { code: 'mt', name: 'Maltese', countryCode: 'MT' },
    { code: 'is', name: 'Icelandic', countryCode: 'IS' },
    { code: 'ga', name: 'Irish', countryCode: 'IE' },
    { code: 'cy', name: 'Welsh', countryCode: 'GB' },
    { code: 'af', name: 'Afrikaans', countryCode: 'ZA' },
    { code: 'sq', name: 'Albanian', countryCode: 'AL' },
];

export default isoLanguages;

import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Grid, Paper, Button, LinearProgress, Breadcrumbs, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TranslateIcon from '@mui/icons-material/Translate';
import HomeIcon from '@mui/icons-material/Home';
import { CircleFlag } from 'react-circle-flags';
import isoLanguages from './isoLanguages';

// Get language details from isoLanguages
const getLanguageDetails = (code) => {
    return isoLanguages.find((lang) => lang.code.toLowerCase() === code.toLowerCase()) || { name: code, code: 'un' };
};

const DashboardHome = () => {
    const [activeProjects, setActiveProjects] = useState(0);
    const [completedProjects, setCompletedProjects] = useState(0);
    const [recentProjects, setRecentProjects] = useState([]);
    const [wordsTranslated, setWordsTranslated] = useState(0);
    const [topLanguages, setTopLanguages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [apiUsage, setApiUsage] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const token = useSelector((state) => state.auth.token);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const projectsResponse = await axios.get('/api/projects', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const projects = projectsResponse.data;
                setActiveProjects(projects.filter(project => !project.completed).length);
                setCompletedProjects(projects.filter(project => project.completed).length);
                setRecentProjects(projects.slice(0, 3));

                const statsResponse = await axios.get('/api/stats', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const { wordsTranslated, topLanguages } = statsResponse.data;
                setWordsTranslated(wordsTranslated);
                setTopLanguages(topLanguages);

                const apiUsageResponse = await axios.get('/api/deepl/usage', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                setApiUsage(apiUsageResponse.data);
                setLoading(false);
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    localStorage.removeItem('token');
                    navigate('/login');
                } else {
                    setError('Failed to load dashboard data.');
                    setLoading(false);
                }
            }
        };
        fetchData();
    }, [token, navigate]);

    if (loading) return <Typography variant="h5">Loading...</Typography>;
    if (error) return <Typography variant="h5" color="error">{error}</Typography>;

    const breadcrumbs = location.pathname.split('/').filter(x => x).map((path, index, arr) => (
        <Link
            key={path}
            color={index === arr.length - 1 ? 'textPrimary' : 'inherit'}
            onClick={() => navigate(`/${arr.slice(0, index + 1).join('/')}`)}
            sx={{ cursor: 'pointer', textTransform: 'capitalize' }}
        >
            {path || 'Home'}
        </Link>
    ));

    const StyledPaper = styled(Paper)(({ theme }) => ({
        padding: '20px',
        textAlign: 'center',
        color: theme.palette.text.primary,
        borderRadius: '16px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    }));

    const ProjectCard = styled(Paper)(({ theme }) => ({
        padding: '20px',
        borderRadius: '12px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        borderLeft: '5px solid #4a90e2',
        background: '#fff',
        '&:hover': {
            transform: 'scale(1.02)',
            transition: 'transform 0.2s ease-in-out',
        }
    }));

    const StyledButton = styled(Button)(({ theme }) => ({
        padding: '10px 20px',
        borderRadius: '8px',
        background: 'linear-gradient(135deg, #4a90e2, #5cb1ff)',
        color: '#fff',
        fontWeight: 'bold',
        '&:hover': {
            background: 'linear-gradient(135deg, #5cb1ff, #4a90e2)',
        }
    }));

    return (
        <Container maxWidth="lg" sx={{ marginTop: '30px' }}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                <Link color="inherit" onClick={() => navigate('/dashboard')} sx={{ cursor: 'pointer' }}>
                    <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" /> Dashboard
                </Link>
                {breadcrumbs}
            </Breadcrumbs>

            <Box sx={{ marginBottom: '20px', width: '100%' }}>
                <img
                    src="images/workspace-default.png"
                    alt="Workspace"
                    style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
                />
            </Box>

            <Typography variant="h4" gutterBottom textAlign="left">
                Your Translation Workspace
            </Typography>
            <Typography variant="body1" textAlign="left" gutterBottom marginBottom="15px">
                This is your hub for managing all translation projects. Track your progress, explore language insights, and manage your active and completed tasks.
            </Typography>

            {/* Project Overview */}
            <Grid container spacing={4}>
                <Grid item xs={12} md={4}>
                    <StyledPaper sx={{ background: 'linear-gradient(135deg, #d0f0fd 0%, #a0d9ff 100%)' }}>
                        <WorkOutlineIcon fontSize="large" color="primary" />
                        <Typography variant="h6" sx={{ mt: 1 }}>Active Projects</Typography>
                        <Typography variant="h4">{activeProjects}</Typography>
                    </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <StyledPaper sx={{ background: 'linear-gradient(135deg, #e0ffe1 0%, #b3ffb5 100%)' }}>
                        <CheckCircleOutlineIcon fontSize="large" color="success" />
                        <Typography variant="h6" sx={{ mt: 1 }}>Completed Projects</Typography>
                        <Typography variant="h4">{completedProjects}</Typography>
                    </StyledPaper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <StyledPaper sx={{ background: 'linear-gradient(135deg, #f3e1ff 0%, #dab6ff 100%)' }}>
                        <TranslateIcon fontSize="large" color="secondary" />
                        <Typography variant="h6" sx={{ mt: 1 }}>Words Translated This Month</Typography>
                        <Typography variant="h4">{wordsTranslated}</Typography>
                    </StyledPaper>
                </Grid>
            </Grid>

            {/* Recent Projects */}
            <Box sx={{ marginTop: '40px' }}>
                <Typography variant="h5" gutterBottom>
                    Recent Projects
                </Typography>
                {recentProjects.length > 0 ? (
                    <Grid container spacing={4}>
                        {recentProjects.map((project) => (
                            <Grid item xs={12} md={4} key={project._id}>
                                <ProjectCard>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                        {project.projectName}
                                    </Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                                        <CircleFlag countryCode={getLanguageDetails(project.sourceLanguage).code.toLowerCase()} height="24" width="24" />
                                        <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                                            <strong>Source:</strong> {getLanguageDetails(project.sourceLanguage).name || project.sourceLanguage}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 1 }}>
                                        <CircleFlag countryCode={getLanguageDetails(project.targetLanguage).code.toLowerCase()} height="24" width="24" />
                                        <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                                            <strong>Target:</strong> {getLanguageDetails(project.targetLanguage).name || project.targetLanguage}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ textAlign: 'center', mt: 2 }}>
                                        <Typography variant="body2" color="text.secondary" gutterBottom>
                                            <strong>Progress:</strong> {project.progress}%
                                        </Typography>
                                        <LinearProgress
                                            variant="determinate"
                                            value={project.progress || 0}
                                            sx={{ mt: 1, height: 8, borderRadius: 4 }}
                                        />
                                        <StyledButton
                                            onClick={() => window.location.href = `/#/dashboard/projects/${project._id}/translate`}
                                            sx={{ mt: 2 }}
                                        >
                                            Continue Translation
                                        </StyledButton>
                                    </Box>
                                </ProjectCard>
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Typography>No recent projects found.</Typography>
                )}
            </Box>

            {/* Top Languages Used */}
            <Box sx={{ marginTop: '40px' }}>
                <Typography variant="h5" gutterBottom>
                    Top Languages Used
                </Typography>
                <Grid container spacing={4}>
                    {topLanguages.map((language, index) => (
                        <Grid item xs={12} md={4} key={index}>
                            <StyledPaper sx={{ backgroundColor: '#f0f4ff', textAlign: 'center', padding: 4 }}>
                                <CircleFlag countryCode={getLanguageDetails(language.name).code.toLowerCase()} height="48" width="48" />
                                <Typography variant="h6" sx={{ mt: 2 }}>{getLanguageDetails(language.name).name}</Typography>
                                <Typography variant="body2">Used {language.count} times</Typography>
                            </StyledPaper>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {/* API Usage with Progress Bar */}
            <Box sx={{ marginTop: '40px' }}>
                <Typography variant="h5" gutterBottom>
                    DeepL API Usage
                </Typography>
                <StyledPaper>
                    {apiUsage && apiUsage.character_count !== undefined && apiUsage.character_limit !== undefined ? (
                        <>
                            <Typography variant="body1">
                                {apiUsage.character_count} characters translated out of {apiUsage.character_limit} available.
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                <LinearProgress
                                    variant="determinate"
                                    value={(apiUsage.character_count / apiUsage.character_limit) * 100}
                                    sx={{ flexGrow: 1, marginRight: '10px', height: 8, borderRadius: 4 }}
                                />
                                <Typography variant="body2">
                                    {(apiUsage.character_count / apiUsage.character_limit * 100).toFixed(2)}%
                                </Typography>
                            </Box>
                        </>
                    ) : (
                        <Typography variant="body1">API usage data unavailable.</Typography>
                    )}
                </StyledPaper>
            </Box>
        </Container>
    );
};

export default DashboardHome;

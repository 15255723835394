import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
    Avatar,
    TextField
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Flag from 'react-world-flags'; // For displaying flags
import isoLanguages from './isoLanguages'; // Import the ISO languages

const TeamPage = () => {
    const [translators, setTranslators] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [openInviteDialog, setOpenInviteDialog] = useState(false);
    const [openAddUserDialog, setOpenAddUserDialog] = useState(false); // New state for manual userID dialog
    const [inviteEmail, setInviteEmail] = useState('');
    const [userID, setUserID] = useState(''); // New state for userID input
    const token = useSelector((state) => state.auth.token);
    const navigate = useNavigate();

    // Fetch translators when the component mounts
    useEffect(() => {
        const fetchTranslators = async () => {
            try {
                const response = await axios.get('/api/team', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setTranslators(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching translators:', err);
                setError('Failed to load translators');
                setLoading(false);
            }
        };

        fetchTranslators();
    }, [token]);

    const handleInviteTranslator = async () => {
        try {
            await axios.post(
                '/api/team/invite',
                { email: inviteEmail },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            alert('Invitation sent successfully');
            setOpenInviteDialog(false);
            setInviteEmail('');
            setTranslators([...translators, { email: inviteEmail, status: 'pending' }]);
        } catch (err) {
            console.error('Error sending invitation:', err);
            alert('Failed to send invitation');
        }
    };

    const handleAddUserToTeam = async () => {
        try {
            await axios.post(
                '/api/team/add-user',
                { userId: userID },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            alert('User added successfully to the team');
            setOpenAddUserDialog(false);
            setUserID('');
            // Fetch the updated team members
            const response = await axios.get('/api/team', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setTranslators(response.data);
        } catch (err) {
            console.error('Error adding user to the team:', err);
            alert('Failed to add user to the team');
        }
    };

    const handleEditProjects = (translator) => {
        navigate(`/dashboard/team/${translator._id}/edit-projects`);
    };

    const handleRemoveTranslator = async (translatorId) => {
        if (!window.confirm('Are you sure you want to remove this translator from the team?')) return;

        try {
            await axios.delete(`/api/team/translators/${translatorId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setTranslators(translators.filter((translator) => translator.userId._id !== translatorId));
            alert('Translator removed successfully');
        } catch (err) {
            console.error('Error removing translator:', err);
            alert('Failed to remove translator');
        }
    };

    const getInitials = (firstName, lastName) => {
        return `${firstName?.[0] || ''}${lastName?.[0] || ''}`.toUpperCase();
    };

    const getLanguageFlag = (languageCode) => {
        const language = isoLanguages.find((lang) => lang.code.toLowerCase() === languageCode.toLowerCase());
        return language ? language.countryCode : 'UN'; // Default to UN if no match
    };

    const renderFlags = (translatedLanguages) => {
        if (!Array.isArray(translatedLanguages) || translatedLanguages.length === 0) {
            return <Flag code="UN" style={{ width: '25px' }} />; // Default to UN flag if no languages
        }

        return translatedLanguages.map((language) => {
            const flagCode = getLanguageFlag(language); // Get the flag code for each language
            return <Flag key={language} code={flagCode} style={{ width: '25px', marginRight: '5px' }} />;
        });
    };

    if (loading) {
        return (
            <Container sx={{ marginTop: '50px', textAlign: 'center' }}>
                <CircularProgress />
            </Container>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ marginTop: '50px' }}>
            <Typography variant="h4" gutterBottom>
                Team Management
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                <Typography variant="h6">Translators</Typography>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<PersonAddIcon />}
                        onClick={() => setOpenInviteDialog(true)}
                        sx={{ marginRight: '10px' }}
                    >
                        Invite Translator
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setOpenAddUserDialog(true)} // Open userID dialog
                    >
                        Add User by ID
                    </Button>
                </Box>
            </Box>

            {/* Table with translators and translated languages */}
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Assigned Projects</TableCell>
                            <TableCell>Languages</TableCell> {/* New column for translated languages */}
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {translators.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={6} align="center">
                                    No translators have been added to the team yet.
                                </TableCell>
                            </TableRow>
                        ) : (
                            translators.map((translator) => (
                                <TableRow key={translator._id}>
                                    <TableCell>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {translator.userId?.avatarUrl ? (
                                                <Avatar
                                                    src={`${translator.userId.avatarUrl}`}
                                                    alt={translator.userId.firstName || 'User'}
                                                    sx={{ width: 40, height: 40, marginRight: '10px' }}
                                                />
                                            ) : (
                                                <Avatar sx={{ width: 40, height: 40, marginRight: '10px' }}>
                                                    {getInitials(translator.userId?.firstName, translator.userId?.lastName)}
                                                </Avatar>
                                            )}
                                            {translator.userId?.firstName && translator.userId?.lastName
                                                ? `${translator.userId.firstName} ${translator.userId.lastName}`
                                                : 'N/A'}
                                        </Box>
                                    </TableCell>
                                    <TableCell>{translator.userId?.email || 'N/A'}</TableCell>
                                    <TableCell>{translator.status === 'pending' ? 'Pending' : 'Active'}</TableCell>
                                    <TableCell>{translator.assignedProjectsCount || 0}</TableCell>

                                    {/* New Translated Languages Column */}
                                    <TableCell>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            {renderFlags(translator.userId?.translatedLanguages)} {/* Render flags here */}
                                        </Box>
                                    </TableCell>

                                    <TableCell>
                                        {translator.status !== 'pending' && (
                                            <>
                                                <IconButton
                                                    color="primary"
                                                    onClick={() => handleEditProjects(translator)}
                                                >
                                                    <EditIcon />
                                                </IconButton>
                                                <IconButton
                                                    color="secondary"
                                                    onClick={() => handleRemoveTranslator(translator.userId._id)}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </>
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Invite Translator Dialog */}
            <Dialog open={openInviteDialog} onClose={() => setOpenInviteDialog(false)}>
                <DialogTitle>Invite a New Translator</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Translator's Email"
                        type="email"
                        fullWidth
                        value={inviteEmail}
                        onChange={(e) => setInviteEmail(e.target.value)}
                        sx={{ marginBottom: '20px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenInviteDialog(false)}>Cancel</Button>
                    <Button onClick={handleInviteTranslator} variant="contained" color="primary">
                        Send Invitation
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Add User by ID Dialog */}
            <Dialog open={openAddUserDialog} onClose={() => setOpenAddUserDialog(false)}>
                <DialogTitle>Add User by ID</DialogTitle>
                <DialogContent>
                    <TextField
                        label="User ID"
                        type="text"
                        fullWidth
                        value={userID}
                        onChange={(e) => setUserID(e.target.value)}
                        sx={{ marginBottom: '20px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAddUserDialog(false)}>Cancel</Button>
                    <Button onClick={handleAddUserToTeam} variant="contained" color="primary">
                        Add User
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default TeamPage;

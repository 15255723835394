import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Paper, Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import axios from 'axios';
import { useSelector } from 'react-redux';

const AccountSettings = () => {
    const token = useSelector((state) => state.auth.token);
    const [userID, setUserID] = useState(''); // State for storing the user ID
    const [deepLApiKey, setDeepLApiKey] = useState('');
    const [message, setMessage] = useState('');
    const [usageData, setUsageData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false); // For the confirmation dialog

    // Fetch DeepL API key and usage data, along with userID
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                // Fetch user's data (including userID and DeepL API key)
                const response = await axios.get('/api/users/me', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                const { deepLApiKey: apiKey, _id: fetchedUserID } = response.data;
                setDeepLApiKey(apiKey || '');
                setUserID(fetchedUserID); // Set userID from response

                // Fetch DeepL usage data if API key is available
                if (apiKey) {
                    const usageResponse = await axios.get('/api/deepl/usage', {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                    setUsageData(usageResponse.data);
                }
            } catch (err) {
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };
        fetchUserData();
    }, [token]);

    const handleSave = async () => {
        try {
            await axios.put(
                '/api/users/me/ApiKey',
                { deepLApiKey },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setMessage('API Key saved successfully.');
        } catch (err) {
            console.error('Error saving API key:', err);
            setMessage('Error saving API key.');
        }
    };

    const handleClearMemory = async () => {
        try {
            await axios.delete('/api/translation-memory/clear', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessage('Translation memory cleared successfully.');
        } catch (err) {
            console.error('Error clearing translation memory:', err);
            setMessage('Error clearing translation memory.');
        }
    };

    const handleDownloadMemory = async () => {
        try {
            const response = await axios.get('/api/translation-memory/download', {
                headers: { Authorization: `Bearer ${token}` },
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'translation-memory.tmx');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            setMessage('Translation memory downloaded successfully.');
        } catch (err) {
            console.error('Error downloading translation memory:', err);
            setMessage('Error downloading translation memory.');
        }
    };

    const openConfirmationDialog = () => {
        setOpenDialog(true);
    };

    const closeConfirmationDialog = () => {
        setOpenDialog(false);
    };

    const confirmClearMemory = () => {
        handleClearMemory();
        closeConfirmationDialog();
    };

    return (
        <Container maxWidth="sm" sx={{ marginTop: '50px' }}>
            <Paper elevation={3} sx={{ padding: '30px', borderRadius: '10px', textAlign: 'left' }}>
                <Typography variant="h4" gutterBottom align="left">Account Settings</Typography>

                {/* Display User ID */}
                <Box sx={{ marginBottom: '20px', textAlign: 'left' }}>
                    <Typography variant="h6">Your User ID</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        value={userID}
                        disabled
                    />
                    <Typography variant="caption">Copy this User ID to manually add to a team.</Typography>
                </Box>

                {/* API Key Input */}
                <Box sx={{ marginBottom: '20px', textAlign: 'left' }}>
                    <TextField
                        label="DeepL API Key"
                        variant="outlined"
                        fullWidth
                        value={deepLApiKey}
                        onChange={(e) => setDeepLApiKey(e.target.value)}
                    />
                </Box>

                {/* Save Button */}
                <Button variant="contained" color="primary" onClick={handleSave}>
                    Save API Key
                </Button>
                {message && <Typography color="success" sx={{ marginTop: '10px', textAlign: 'left' }}>{message}</Typography>}

                {/* Display usage information */}
                {loading ? (
                    <CircularProgress sx={{ marginTop: '20px' }} />
                ) : usageData ? (
                    <Box sx={{ marginTop: '20px', textAlign: 'left' }}>
                        <Typography variant="h6">Current Usage</Typography>
                        <Typography>Character Count: {usageData.character_count}</Typography>
                        <Typography>Character Limit: {usageData.character_limit}</Typography>
                        <Typography>
                            Percentage Used: {((usageData.character_count / usageData.character_limit) * 100).toFixed(2)}%
                        </Typography>
                    </Box>
                ) : (
                    <Typography sx={{ marginTop: '20px', textAlign: 'left' }}>
                        No usage data available.
                    </Typography>
                )}

                {/* Download Translation Memory Section */}
                <Box sx={{ marginTop: '40px', textAlign: 'left' }}>
                    <Button variant="contained" color="primary" onClick={handleDownloadMemory}>
                        Download Translation Memory
                    </Button>
                </Box>

                {/* Clear Translation Memory Section */}
                <Box sx={{ marginTop: '20px', textAlign: 'left' }}>
                    <Button variant="contained" color="secondary" onClick={openConfirmationDialog}>
                        Clear Translation Memory
                    </Button>
                </Box>

                {/* Confirmation Dialog */}
                <Dialog open={openDialog} onClose={closeConfirmationDialog}>
                    <DialogTitle>Clear Translation Memory</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to clear your translation memory? This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={closeConfirmationDialog} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={confirmClearMemory} color="secondary">
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            </Paper>
        </Container>
    );
};

export default AccountSettings;

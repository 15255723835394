import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, Paper, Box, Button, Table, TableBody, TableCell, Link, Breadcrumbs, TableContainer, TableHead, TableRow, Checkbox, LinearProgress, IconButton, TableSortLabel } from '@mui/material';
import Flag from 'react-world-flags';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import FolderIcon from '@mui/icons-material/Folder';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import TranslateIcon from '@mui/icons-material/Translate';
import isoLanguages from './isoLanguages'; // Import the centralized language options
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const TranslationProjects = () => {
    const [createdProjects, setCreatedProjects] = useState([]);
    const [sharedProjects, setSharedProjects] = useState([]);
    const [selectedProjects, setSelectedProjects] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'projectName', direction: 'asc' });
    const token = useSelector((state) => state.auth.token);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentProject, setCurrentProject] = useState(null);
    const location = useLocation();

    const handleUnauthorized = useCallback(() => {
        localStorage.removeItem('token');
        navigate('/login', { replace: true });
    }, [navigate]);

    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const response = await axios.get('/api/projects', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const created = [];
                const shared = [];

                response.data.forEach((project) => {
                    const totalSections = project.sections.length;
                    const approvedSections = project.approvedSections ? project.approvedSections.length : 0;
                    const progress = totalSections > 0 ? (approvedSections / totalSections) * 100 : 0;

                    const updatedProject = {
                        ...project,
                        progress: progress.toFixed(2),
                    };

                    if (project.createdBy) {
                        shared.push(updatedProject);
                    } else {
                        created.push(updatedProject);
                    }
                });

                setCreatedProjects(created);
                setSharedProjects(shared);
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    handleUnauthorized();
                } else {
                    console.error('Error fetching projects:', err);
                }
            }
        };

        fetchProjects();
    }, [token, handleUnauthorized]);

    const handleDownload = async (projectId, fileType = 'translated') => {
        try {
            const metadataResponse = await axios.get(`/api/export/${projectId}/metadata`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const { fileType: projectFileType, fileName } = metadataResponse.data;

            // Determine the export route based on file type
            let route;
            if (projectFileType === 'xliff' || projectFileType === 'xlf') {
                // Use `export-xliff` for updated original XLIFF files
                route = `/api/export/${projectId}/export-xliff`;
            } else if (projectFileType === 'idml') {
                route = `/api/export/${projectId}/export`; // Existing support for IDML
            } else if (projectFileType === 'pptx') {
                route = `/api/export/${projectId}/export-pptx`; // Existing support for PowerPoint
            } else if (projectFileType === 'html') {
                route = `/api/export/${projectId}/export-html`; // Existing support for HTML export
            } else if (projectFileType === 'json') {
                route = `/api/export/${projectId}/export-json`; // Existing support for JSON export
            } else if (projectFileType === 'xml') {
                route = `/api/export/${projectId}/export-xml`; // Add support for XML export
            } else {
                route = `/api/export/${projectId}/export-word`; // Fallback to Word export
            }

            const downloadResponse = await axios.get(route, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // Create a download link and trigger the download
            const url = window.URL.createObjectURL(new Blob([downloadResponse.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName || `${projectId}_translated.${fileType}`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Clean up after download
        } catch (err) {
            console.error('Error exporting project:', err);
        }
    };


    const handleExportAsXliff = async (projectId) => {
        try {
            const route = `/api/export/${projectId}/export-xliff-file`;

            const response = await axios.get(route, {
                responseType: 'blob',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const fileName = `${projectId}_translated.xliff`;
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link); // Clean up after download
        } catch (err) {
            console.error('Error exporting project as XLIFF:', err);
        }
    };



    const handleDeleteProject = async (projectId) => {
        if (!window.confirm('Are you sure you want to delete this project?')) return;

        try {
            await axios.delete(`/api/projects/${projectId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setCreatedProjects(createdProjects.filter((project) => project._id !== projectId));
        } catch (err) {
            console.error('Error deleting project:', err);
        }
    };

    const handleSelectProject = (projectId) => {
        setSelectedProjects((prevSelected) => {
            if (prevSelected.includes(projectId)) {
                return prevSelected.filter((id) => id !== projectId);
            } else {
                return [...prevSelected, projectId];
            }
        });
    };

    const handleSort = (column) => {
        setSortConfig((prevSortConfig) => {
            const isAsc = prevSortConfig.key === column && prevSortConfig.direction === 'asc';
            return { key: column, direction: isAsc ? 'desc' : 'asc' };
        });
    };

    const sortProjects = (projects) => {
        return [...projects].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
    };

    const getLanguageDetails = (code) => {
        return isoLanguages.find((lang) => lang.code.toLowerCase() === code.toLowerCase()) || { name: code, countryCode: 'UN' };
    };

    const handleOpenMenu = (event, projectId) => {
        setAnchorEl(event.currentTarget);
        setCurrentProject(projectId);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
        setCurrentProject(null);
    };

    const breadcrumbs = location.pathname.split('/').filter(x => x).map((path, index, arr) => (
        <Link
            key={path}
            color={index === arr.length - 1 ? 'textPrimary' : 'inherit'}
            onClick={() => navigate(`/${arr.slice(0, index + 1).join('/')}`)}
            sx={{ cursor: 'pointer', textTransform: 'capitalize' }}
        >
            {path || 'Home'}
        </Link>
    ));

    return (
        <Container maxWidth="lg" sx={{ marginTop: '50px' }}>
            <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
                <Link color="inherit" onClick={() => navigate('/projects')} sx={{ cursor: 'pointer' }}>
                    <FolderIcon sx={{ mr: 0.5 }} fontSize="inherit" /> Projects
                </Link>
                {breadcrumbs}
            </Breadcrumbs>
            <Typography variant="h4" gutterBottom>Translation Projects</Typography>

            <Typography variant="h5" sx={{ marginTop: '20px' }}>Projects You Created</Typography>
            {createdProjects.length > 0 ? (
                <>
                    {selectedProjects.length > 0 && (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                            <IconButton onClick={() => navigate(`/dashboard/projects/${selectedProjects[0]}/edit`)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton onClick={() => handleDeleteProject(selectedProjects[0])}>
                                <DeleteIcon />
                            </IconButton>
                            <Box>
                                <MenuItem
                                    onClick={(event) => handleOpenMenu(event, selectedProjects[0])}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        backgroundColor: '#007BFF',
                                        borderRadius: '10px',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: '#0056b3',
                                        },
                                    }}
                                >
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <CloudDownloadIcon sx={{ marginRight: 1 }} />
                                    </Box>
                                    <ArrowDropDownIcon />
                                </MenuItem>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl) && currentProject === selectedProjects[0]}
                                    onClose={handleCloseMenu}
                                >
                                    <MenuItem onClick={() => { handleDownload(selectedProjects[0], 'original'); handleCloseMenu(); }}>
                                        Original File
                                    </MenuItem>
                                    <MenuItem onClick={() => { handleDownload(selectedProjects[0], 'translated'); handleCloseMenu(); }}>
                                        Translated File
                                    </MenuItem>
                                    <MenuItem onClick={() => { handleExportAsXliff(selectedProjects[0]); handleCloseMenu(); }}>
                                        Export as XLIFF
                                    </MenuItem>
                                </Menu>


                            </Box>


                        </Box>
                    )}

                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            indeterminate={selectedProjects.length > 0 && selectedProjects.length < createdProjects.length}
                                            checked={selectedProjects.length === createdProjects.length}
                                            onChange={(e) => setSelectedProjects(e.target.checked ? createdProjects.map((p) => p._id) : [])}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'projectName'}
                                            direction={sortConfig.direction}
                                            onClick={() => handleSort('projectName')}
                                        >
                                            Project Name
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'sourceLanguage'}
                                            direction={sortConfig.direction}
                                            onClick={() => handleSort('sourceLanguage')}
                                        >
                                            Source
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>
                                        <TableSortLabel
                                            active={sortConfig.key === 'targetLanguage'}
                                            direction={sortConfig.direction}
                                            onClick={() => handleSort('targetLanguage')}
                                        >
                                            Target
                                        </TableSortLabel>
                                    </TableCell>
                                    <TableCell>Progress</TableCell>
                                    <TableCell>Deadline</TableCell>
                                    <TableCell>Translate</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {sortProjects(createdProjects).map((project) => (
                                    <TableRow key={project._id}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={selectedProjects.includes(project._id)}
                                                onChange={() => handleSelectProject(project._id)}
                                            />
                                        </TableCell>
                                        <TableCell>{project.projectName}</TableCell>
                                        <TableCell>
                                            <Flag code={getLanguageDetails(project.sourceLanguage).countryCode} style={{ marginRight: '10px', width: '20px' }} />
                                            {getLanguageDetails(project.sourceLanguage).name}
                                        </TableCell>
                                        <TableCell>
                                            <Flag code={getLanguageDetails(project.targetLanguage).countryCode} style={{ marginRight: '10px', width: '20px' }} />
                                            {getLanguageDetails(project.targetLanguage).name}
                                        </TableCell>
                                        <TableCell>
                                            <LinearProgress variant="determinate" value={project.progress} />
                                            <Typography variant="body2">{project.progress}%</Typography>
                                        </TableCell>
                                        <TableCell>{project.deadline ? new Date(project.deadline).toLocaleDateString() : 'N/A'}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => navigate(`/dashboard/projects/${project._id}/translate`)} color="primary">
                                                <TranslateIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <Box textAlign="center" sx={{ mt: 4 }}>
                    <Typography variant="h6">No projects found.</Typography>
                    <Button variant="contained" color="primary" onClick={() => navigate('/dashboard/projects/new-translation')}>
                        Create New Project
                    </Button>
                </Box>
            )}

            <Typography variant="h5" sx={{ marginTop: '40px' }}>Shared Projects</Typography>
            {sharedProjects.length > 0 ? (
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Project Name</TableCell>
                                <TableCell>Source</TableCell>
                                <TableCell>Target</TableCell>
                                <TableCell>Progress</TableCell>
                                <TableCell>Deadline</TableCell>
                                <TableCell>Created By</TableCell>
                                <TableCell>Translate</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortProjects(sharedProjects).map((project) => (
                                <TableRow key={project._id}>
                                    <TableCell>{project.projectName}</TableCell>
                                    <TableCell>
                                        <Flag code={getLanguageDetails(project.sourceLanguage).countryCode} style={{ marginRight: '10px', width: '20px' }} />
                                        {getLanguageDetails(project.sourceLanguage).name}
                                    </TableCell>
                                    <TableCell>
                                        <Flag code={getLanguageDetails(project.targetLanguage).countryCode} style={{ marginRight: '10px', width: '20px' }} />
                                        {getLanguageDetails(project.targetLanguage).name}
                                    </TableCell>
                                    <TableCell>
                                        <LinearProgress variant="determinate" value={project.progress} />
                                        <Typography variant="body2">{project.progress}%</Typography>
                                    </TableCell>
                                    <TableCell>{project.deadline ? new Date(project.deadline).toLocaleDateString() : 'N/A'}</TableCell>
                                    <TableCell>
                                        <Typography variant="body2" color="textSecondary">
                                            {project.createdBy}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => navigate(`/dashboard/projects/${project._id}/translate`)} color="primary">
                                            <TranslateIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Box textAlign="center" sx={{ mt: 4 }}>
                    <Typography variant="h6">No shared projects found.</Typography>
                </Box>
            )}
        </Container>
    );
};

export default TranslationProjects;

import * as React from 'react';
import { Box, Typography, Container, Card, Tabs, Tab } from '@mui/material';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import GTranslateIcon from '@mui/icons-material/GTranslate';
import GroupIcon from '@mui/icons-material/Group';

const features1 = [
    {
        icon: <DashboardCustomizeIcon fontSize="large" />,
        title: 'Project Dashboard',
        description: `
            Our Project Dashboard is designed with simplicity and functionality in mind, making it easy to create, manage, and edit translation projects all in one place. Track the progress of ongoing translations and view detailed reports to monitor key performance indicators for each project. The dashboard streamlines project tracking by displaying deadlines, assigned team members, and completion percentages, providing you with a complete overview of your translation workflow.
        `,
        image: '/images/placeholder_dashboard.png',
    },
    {
        icon: <AutoAwesomeIcon fontSize="large" />,
        title: 'AI Translation',
        description: `
            Experience the power of AI-driven translations with features that deliver quality, context-aware translations in seconds. With support for industry-specific terminology, the AI Translation tool adapts to various content types�from casual text to formal business language�ensuring consistency and accuracy across different documents. Utilize auto-detection of source languages, customizable translation tones, and glossary integration to control terminology. The tool�s advanced algorithms continuously improve with usage, making translations smarter and more precise over time. Speed, flexibility, and AI-enhanced quality make this tool essential for fast-paced projects.
        `,
        image: '/images/placeholder_ai_translation.png',
    },
    {
        icon: <GTranslateIcon fontSize="large" />,
        title: 'Integrated Translation Tools',
        description: `
            Our platform includes powerful integrated translation tools like glossaries and translation memory to enhance translation quality and consistency. Glossaries enable translators to maintain specific terminology across projects, ensuring accuracy and uniformity. Translation memory saves previously translated segments, allowing translators to reuse content, improve efficiency, and reduce costs. These tools work together to boost productivity and maintain high standards in every project.
        `,
        image: '/images/placeholder_translation_tools.png',
    },
    {
        icon: <GroupIcon fontSize="large" />,
        title: 'Team Collaboration',
        description: `
            Team Collaboration tools foster seamless communication among translators, reviewers, and project managers, allowing them to work together in real-time within the dashboard. Share feedback instantly, coordinate on project details, and ensure everyone stays informed about updates. With shared access to files, glossaries, and project notes, collaboration is easy and efficient, helping teams deliver accurate translations faster.
        `,
        image: '/images/placeholder_collaboration.png',
    },
];

function FeaturesPage1() {
    const [selectedTab, setSelectedTab] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    return (
        <Container sx={{ py: { xs: 8, sm: 16 } }}>
            <Box sx={{ mb: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Key Features
                </Typography>
                <Typography variant="body1" color="text.secondary">
                    Discover some of the essential tools and features that make managing your translation projects effortless.
                </Typography>
            </Box>

            {/* Tabs for Features */}
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 4 }}>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    variant="fullWidth"
                    aria-label="features tabs"
                >
                    {features1.map((feature, index) => (
                        <Tab
                            key={index}
                            label={
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    {feature.icon}
                                    <Typography variant="body1">{feature.title}</Typography>
                                </Box>
                            }
                            {...a11yProps(index)}
                        />
                    ))}
                </Tabs>
            </Box>

            {/* Tab Panel Content */}
            {features1.map((feature, index) => (
                <TabPanel key={index} value={selectedTab} index={index}>
                    <Card variant="outlined" sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, p: 3 }}>
                        <Box sx={{ flex: 1, p: 2 }}>
                            <Typography variant="h5" gutterBottom>
                                {feature.title}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {feature.description}
                            </Typography>
                        </Box>
                        <Box sx={{ flex: 1.5, p: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img
                                src={feature.image}
                                alt={feature.title}
                                style={{ maxWidth: '100%', borderRadius: 8 }}
                            />
                        </Box>
                    </Card>
                </TabPanel>
            ))}
        </Container>
    );
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
        </Box>
    );
}

function a11yProps(index) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

export default FeaturesPage1;

import React, { useState, useCallback, useEffect } from 'react';
import { jwtDecode } from "jwt-decode"; // Import JWT decode library if not already imported
import { useDropzone } from 'react-dropzone';
import {
    Container, Button, Typography, Paper, Box, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, CircularProgress, Input, Alert, TextField, MenuItem,
    LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, FormControlLabel, Tooltip
} from '@mui/material';
import { CircleFlag } from 'react-circle-flags';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/CloudDownload';
import AddIcon from '@mui/icons-material/Add';



const LanguageSettings = () => {
    const token = useSelector((state) => state.auth.token);
    const [message, setMessage] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [tmxFile, setTmxFile] = useState(null);
    const [uploadingTmx, setUploadingTmx] = useState(false);
    const [sourceLanguage, setSourceLanguage] = useState('');
    const [targetLanguage, setTargetLanguage] = useState('');
    const [glossaryName, setGlossaryName] = useState('');
    const [glossaryFile, setGlossaryFile] = useState(null);
    const [uploadingGlossary, setUploadingGlossary] = useState(false);
    const [glossaryUploadProgress, setGlossaryUploadProgress] = useState(0);
    const [glossaries, setGlossaries] = useState([]);
    const [selectedGlossaryId, setSelectedGlossaryId] = useState([]); // Updated to store just the ID
    const navigate = useNavigate();
    const [preferredAlgorithm, setPreferredAlgorithm] = useState('exact');
    const [xmlParsingMode, setXmlParsingMode] = useState('generic');
    const [protectHtmlTags, setProtectHtmlTags] = useState(false);

    let currentUserId = null;

    if (token) {
        const decodedToken = jwtDecode(token); // Decode the token
        currentUserId = decodedToken.id; // Extract the user ID
    }

    const languages = [
        { code: 'EN', label: 'English', countryCode: 'gb' },
        { code: 'ES', label: 'Spanish', countryCode: 'es' },
        { code: 'FR', label: 'French', countryCode: 'fr' },
        { code: 'DE', label: 'German', countryCode: 'de' },
        { code: 'ZH', label: 'Chinese', countryCode: 'cn' },
        { code: 'JA', label: 'Japanese', countryCode: 'jp' },
        { code: 'IT', label: 'Italian', countryCode: 'it' },
    ];

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await axios.get('/api/user-settings', {
                    headers: { Authorization: `Bearer ${token}` },
                });

                // Set preferred algorithm
                setPreferredAlgorithm(response.data.preferredAlgorithm || 'exact');

                // Set XML parsing settings
                if (response.data.xmlSettings) {
                    setXmlParsingMode(response.data.xmlSettings.parsingMode || 'generic');
                    setProtectHtmlTags(response.data.xmlSettings.protectHtmlTags || false);
                }
            } catch (error) {
                console.error('Failed to load user settings:', error);
                setMessage({ type: 'error', text: 'Failed to load user settings.' });
            }
        };
        fetchSettings();
    }, [token]);

    useEffect(() => {
        const fetchGlossaries = async () => {
            try {
                const response = await axios.get('/api/glossary', {
                    headers: { Authorization: `Bearer ${token}` },
                });
                console.log("Fetched Glossaries:", response.data);
                setGlossaries(response.data);
            } catch (error) {
                setMessage({ type: 'error', text: 'Failed to load glossaries.' });
            }
        };
        fetchGlossaries();
    }, [token]);

    // Save XML Parsing Settings
    const saveXmlParsingSettings = async () => {
        try {
            await axios.put('/api/user-settings/xml', {
                xmlParsingMode,
                protectHtmlTags,
            }, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setMessage({ type: 'success', text: 'XML parsing settings saved successfully.' });
        } catch (error) {
            console.error('Error saving XML parsing settings:', error);
            setMessage({ type: 'error', text: 'Failed to save XML parsing settings.' });
        }
    };

    const handleDeleteGlossary = async () => {
        if (!selectedGlossaryId) return;

        try {
            await axios.delete(`/api/glossary/${selectedGlossaryId}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            setGlossaries((prevGlossaries) => prevGlossaries.filter((g) => g._id !== selectedGlossaryId));
            setMessage({ type: 'success', text: 'Glossary deleted successfully.' });
            setSelectedGlossaryId(null); // Clear selection after deletion
        } catch (error) {
            setMessage({ type: 'error', text: 'Failed to delete glossary.' });
        }
    };

    const handleClearMemory = async () => {
        try {
            if (!currentUserId) {
                console.error('User ID is not available.');
                setMessage({ text: 'User not authenticated.', type: 'error' });
                return;
            }

            await axios.delete('/api/translation-memory/clear', {
                headers: { Authorization: `Bearer ${token}` },
                data: { userId: currentUserId }, // Pass the user ID
            });

            setMessage({ text: 'Translation memory cleared successfully.', type: 'success' });
        } catch (err) {
            console.error('Error clearing translation memory:', err);
            setMessage({ text: 'Error clearing translation memory.', type: 'error' });
        }
    };



    const handleAlgorithmChange = async (algorithm) => {
        setPreferredAlgorithm(algorithm); // Update the UI immediately

        try {
            await axios.put(
                '/api/user-settings/algorithm',
                { preferredAlgorithm: algorithm },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setMessage({ type: 'success', text: 'Preferred algorithm updated successfully.' });
        } catch (error) {
            console.error('Error updating preferred algorithm:', error);
            setMessage({ type: 'error', text: 'Failed to update preferred algorithm.' });
        }
    };

    const handleDownloadMemory = async () => {
        setLoadingDownload(true);
        try {
            const response = await axios.get('api/translation-memory/download', {
                headers: { Authorization: `Bearer ${token}` },
                responseType: 'blob',
            });

            const blob = new Blob([response.data], { type: 'application/xml' });
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = 'translation_memory.tmx';
            link.click();
            window.URL.revokeObjectURL(downloadUrl);
            setMessage({ text: 'Download successful.', type: 'success' });
        } catch (err) {
            setMessage({ text: 'Error downloading translation memory.', type: 'error' });
        } finally {
            setLoadingDownload(false);
        }
    };

    const handleFileUpload = async () => {
        if (!tmxFile) {
            setMessage({ text: 'Please select a TMX file to upload.', type: 'error' });
            return;
        }

        setUploadingTmx(true);
        const formData = new FormData();
        formData.append('file', tmxFile);

        try {
            await axios.post('/api/translation-memory/upload', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });
            setMessage({ text: 'Translation memory uploaded successfully.', type: 'success' });
        } catch (err) {
            setMessage({ text: 'Error uploading translation memory.', type: 'error' });
        } finally {
            setUploadingTmx(false);
        }
    };

    const openConfirmationDialog = () => setOpenDialog(true);
    const closeConfirmationDialog = () => setOpenDialog(false);
    const confirmClearMemory = () => {
        handleClearMemory();
        closeConfirmationDialog();
    };

    const handleGlossaryUpload = async () => {
        if (!glossaryName || !sourceLanguage || !targetLanguage) {
            setMessage({ type: 'error', text: 'Please fill in all fields (glossary name, source and target languages).' });
            return;
        }

        const formData = new FormData();
        if (glossaryFile) formData.append('file', glossaryFile);
        formData.append('glossaryName', glossaryName);
        formData.append('sourceLanguage', sourceLanguage);
        formData.append('targetLanguage', targetLanguage);

        setUploadingGlossary(true);

        try {
            const response = await axios.post('/api/glossary/upload', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setGlossaryUploadProgress(progress);
                },
            });

            setMessage({ type: 'success', text: 'Glossary created successfully.' });

            // Clear form inputs
            setGlossaryFile(null);
            setGlossaryName('');
            setSourceLanguage('');
            setTargetLanguage('');

            // Update glossaries with term count
            const newGlossary = response.data.glossary;
            setGlossaries((prevGlossaries) => [...prevGlossaries, {
                ...newGlossary,
                terms: newGlossary.terms || 0, // Ensure the term count is included
            }]);

        } catch (error) {
            setMessage({ type: 'error', text: 'Error uploading glossary.' });
        } finally {
            setUploadingGlossary(false);
        }
    };

    const onGlossaryDrop = useCallback((acceptedFiles) => {
        setGlossaryFile(acceptedFiles[0]);
        setMessage(null);
        setGlossaryUploadProgress(0);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onGlossaryDrop,
        accept: '.xls, .xlsx',
        multiple: false,
    });

    const handleSelectGlossary = (id) => {
        setSelectedGlossaryId((prevSelectedId) => {
            const newSelection = prevSelectedId === id ? null : id;
            console.log("Toggled Glossary ID:", newSelection); // Debugging selected glossary ID
            return newSelection;
        });
    };

    const handleAddGlossaryTerms = () => {
        if (selectedGlossaryId) {
            navigate(`/dashboard/glossary/${selectedGlossaryId}/terms`);
        }
    };


    return (
        <Container maxWidth="md" sx={{ marginTop: '50px' }}>
            <Typography variant="h4" gutterBottom>Language Settings</Typography>

            {/* Memory Translation Section */}
            <Paper elevation={3} sx={{ padding: '30px', marginBottom: '40px' }}>
                <Typography variant="h5" gutterBottom>Memory Translation</Typography>

                {/* Algorithm Selection */}
                <Box sx={{ marginTop: '20px' }}>
                    <Typography variant="h6">Select Preferred Algorithm</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px', marginTop: '10px' }}>
                        <Tooltip title="Exact Match ensures only exact word matches are used from the translation memory." arrow>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={preferredAlgorithm === 'exact'}
                                        onChange={() => handleAlgorithmChange('exact')}
                                    />
                                }
                                label="Exact Match"
                                sx={{ display: 'flex', alignItems: 'center' }}
                            />
                        </Tooltip>
                        <Tooltip title="Fuzzy Match allows near matches based on similarity, useful for partial matches." arrow>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={preferredAlgorithm === 'fuzzy'}
                                        onChange={() => handleAlgorithmChange('fuzzy')}
                                    />
                                }
                                label="Fuzzy Match"
                                sx={{ display: 'flex', alignItems: 'center' }}
                            />
                        </Tooltip>
                        <Tooltip title="Contextual Match considers surrounding context for more accurate suggestions." arrow>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={preferredAlgorithm === 'contextual'}
                                        onChange={() => handleAlgorithmChange('contextual')}
                                    />
                                }
                                label="Contextual Match"
                                sx={{ display: 'flex', alignItems: 'center' }}
                            />
                        </Tooltip>
                    </Box>
                </Box>



                {/* Download Translation Memory */}
                <Box sx={{ marginTop: '20px' }}>
                    <Typography variant="h6">Download Translation Memory</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleDownloadMemory}
                        disabled={loadingDownload}
                        sx={{ marginTop: '10px' }}
                        startIcon={loadingDownload && <CircularProgress size={24} />}
                    >
                        {loadingDownload ? 'Downloading...' : 'Download TMX File'}
                    </Button>
                </Box>

                {/* Upload Translation Memory */}
                <Box sx={{ marginTop: '20px' }}>
                    <Typography variant="h6">Upload Translation Memory (TMX)</Typography>
                    <Input
                        type="file"
                        inputProps={{ accept: '.tmx' }}
                        onChange={(e) => setTmxFile(e.target.files[0])}
                        sx={{ marginBottom: '10px' }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleFileUpload}
                        disabled={uploadingTmx}
                        startIcon={uploadingTmx && <CircularProgress size={24} />}
                    >
                        {uploadingTmx ? 'Uploading...' : 'Upload TMX File'}
                    </Button>
                </Box>

                {/* Clear Translation Memory */}
                <Box sx={{ marginTop: '20px' }}>
                    <Typography variant="h6">Clear Translation Memory</Typography>
                    <Button variant="contained" color="error" onClick={openConfirmationDialog} sx={{ marginTop: '10px' }}>
                        Clear Translation Memory
                    </Button>
                </Box>
            </Paper>


            {/* Glossary Management Section */}
            <Paper elevation={3} sx={{ padding: '30px', marginBottom: '40px' }}>
                <Typography variant="h5" gutterBottom>Glossary Management</Typography>

                <Box sx={{ marginTop: '20px' }}>
                    <TextField
                        select
                        label="Source Language"
                        value={sourceLanguage}
                        onChange={(e) => setSourceLanguage(e.target.value)}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                    >
                        {languages.map((lang) => (
                            <MenuItem key={lang.code} value={lang.code}>
                                <CircleFlag countryCode={lang.countryCode} style={{ marginRight: '10px', width: '20px', height: '20px' }} />
                                {lang.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        select
                        label="Target Language"
                        value={targetLanguage}
                        onChange={(e) => setTargetLanguage(e.target.value)}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                    >
                        {languages.map((lang) => (
                            <MenuItem key={lang.code} value={lang.code}>
                                <CircleFlag countryCode={lang.countryCode} style={{ marginRight: '10px', width: '20px', height: '20px' }} />
                                {lang.label}
                            </MenuItem>
                        ))}
                    </TextField>

                    <TextField
                        label="Glossary Name"
                        value={glossaryName}
                        onChange={(e) => setGlossaryName(e.target.value)}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                    />
                </Box>

                <Box
                    {...getRootProps()}
                    sx={{
                        border: '2px dashed gray',
                        borderRadius: '10px',
                        padding: '30px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        backgroundColor: isDragActive ? '#f0f0f0' : '#fafafa',
                        marginBottom: 2,
                    }}
                >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <Typography>Drop the file here...</Typography>
                    ) : (
                        <Typography>
                            {glossaryFile ? `Selected File: ${glossaryFile.name}` : 'Optional: Drag and drop an .xls or .xlsx file here, or click to select'}
                        </Typography>
                    )}
                </Box>

                {glossaryFile && (
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                        <Typography variant="body2">File: {glossaryFile.name}</Typography>
                        <Box sx={{ flexGrow: 1, ml: 2 }}>
                            <LinearProgress variant="determinate" value={glossaryUploadProgress} />
                        </Box>
                    </Box>
                )}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleGlossaryUpload}
                    disabled={uploadingGlossary}
                    sx={{ marginTop: '10px' }}
                >
                    {uploadingGlossary ? 'Uploading...' : 'Create Glossary'}
                </Button>

                {message && (
                    <Alert severity={message.type} sx={{ marginTop: '20px' }}>
                        {message.text}
                    </Alert>
                )}
            </Paper>

            {/* Glossaries Table Section */}
            <Paper elevation={3} sx={{ padding: '30px', marginBottom: '40px' }}>
                <Typography variant="h5" gutterBottom>Glossaries</Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={handleAddGlossaryTerms}
                        disabled={!selectedGlossaryId}
                        sx={{ marginRight: 1 }}
                    >
                        Add
                    </Button>
                    <Button variant="contained" color="secondary" startIcon={<DownloadIcon />} sx={{ marginRight: 1 }}>
                        Download
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDeleteGlossary}
                        startIcon={<DeleteIcon />}
                        disabled={!selectedGlossaryId}
                    >
                        Delete Selected
                    </Button>
                </Box>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Select</TableCell>
                                <TableCell>Glossary Name</TableCell>
                                <TableCell>Source Language</TableCell>
                                <TableCell>Target Language</TableCell>
                                <TableCell>Number of Terms</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {glossaries.map((glossary) => {
                                const isChecked = glossary._id === selectedGlossaryId;

                                // Enhanced Debugging to check glossary selection state
                                console.log(`Rendering Glossary ID: ${glossary._id}, Selected ID: ${selectedGlossaryId}, isChecked: ${isChecked}`);

                                return (
                                    <TableRow key={glossary._id}>
                                        <TableCell>
                                            <Checkbox
                                                checked={isChecked}
                                                onChange={() => {
                                                    console.log(`Checkbox clicked for Glossary ID: ${glossary._id}`);
                                                    handleSelectGlossary(glossary._id);
                                                }}
                                            />
                                        </TableCell>
                                        <TableCell>{glossary.name}</TableCell>
                                        <TableCell>
                                            {glossary.sourceLanguage}
                                            <CircleFlag
                                                countryCode={languages.find(lang => lang.code === glossary.sourceLanguage)?.countryCode || 'un'}
                                                style={{ marginLeft: '8px', width: '20px', height: '20px', verticalAlign: 'middle' }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            {glossary.targetLanguage}
                                            <CircleFlag
                                                countryCode={languages.find(lang => lang.code === glossary.targetLanguage)?.countryCode || 'un'}
                                                style={{ marginLeft: '8px', width: '20px', height: '20px', verticalAlign: 'middle' }}
                                            />
                                        </TableCell>
                                        <TableCell>{glossary.terms}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            {/* XML Parsing Settings Section */}
            <Paper elevation={3} sx={{ padding: '30px', marginBottom: '40px' }}>
                <Typography variant="h5" gutterBottom>XML Parsing Settings</Typography>

                {/* Parsing Mode Selection */}
                <Box sx={{ marginTop: '20px' }}>
                    <Typography variant="h6">Select XML Parsing Mode</Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: '20px', marginTop: '10px', alignItems: 'center' }}>
                        <Tooltip title="Generic XML parsing for standard XML documents." arrow>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    checked={xmlParsingMode === 'generic'}
                                    onChange={() => {
                                        setXmlParsingMode('generic');
                                        saveXmlParsingSettings(); // Save directly on change
                                    }}
                                />
                                <Typography
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setXmlParsingMode('generic');
                                        saveXmlParsingSettings(); // Save directly on click
                                    }}
                                >
                                    Generic XML
                                </Typography>
                            </Box>
                        </Tooltip>
                        <Tooltip title="Optimized for Android XML with placeholders like %1$s." arrow>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    checked={xmlParsingMode === 'android'}
                                    onChange={() => {
                                        setXmlParsingMode('android');
                                        saveXmlParsingSettings(); // Save directly on change
                                    }}
                                />
                                <Typography
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setXmlParsingMode('android');
                                        saveXmlParsingSettings(); // Save directly on click
                                    }}
                                >
                                    Android XML
                                </Typography>
                            </Box>
                        </Tooltip>
                        <Tooltip title="Parse XML documents following the DITA standard." arrow>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                    checked={xmlParsingMode === 'dita'}
                                    onChange={() => {
                                        setXmlParsingMode('dita');
                                        saveXmlParsingSettings(); // Save directly on change
                                    }}
                                />
                                <Typography
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        setXmlParsingMode('dita');
                                        saveXmlParsingSettings(); // Save directly on click
                                    }}
                                >
                                    DITA XML
                                </Typography>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>

                {/* Protect HTML Tags in CDATA Sections */}
                <Box sx={{ marginTop: '20px' }}>
                    <Tooltip title="When enabled, HTML tags inside CDATA sections will not be altered or translated." arrow>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={protectHtmlTags}
                                    onChange={() => {
                                        setProtectHtmlTags(!protectHtmlTags);
                                        saveXmlParsingSettings(); // Save directly on change
                                    }}
                                />
                            }
                            label="Protect HTML Tags in CDATA Sections"
                            sx={{ display: 'flex', alignItems: 'center' }}
                        />
                    </Tooltip>
                </Box>
            </Paper>


            <Dialog open={openDialog} onClose={closeConfirmationDialog}>
                <DialogTitle>Clear Translation Memory</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to clear your translation memory? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeConfirmationDialog} color="primary">Cancel</Button>
                    <Button onClick={confirmClearMemory} color="error">Confirm</Button>
                </DialogActions>
            </Dialog>


        </Container>
    );
};

export default LanguageSettings;

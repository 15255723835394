import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#A7C7E7',  // Light Blue for primary elements
            contrastText: '#FFFFFF',  // White text on primary buttons
        },
        secondary: {
            main: '#F9A8D4',  // Soft Pink for secondary elements
            contrastText: '#FFFFFF',  // White text on secondary buttons
        },
        background: {
            default: '#F4F5F7',  // Very Light Grey for page backgrounds
            paper: '#FFFFFF',  // White for paper-like surfaces (cards, dialogs)
        },
        text: {
            primary: '#37474F',  // Charcoal Grey for main text
            secondary: '#607D8B',  // Muted grey-blue for secondary text
        },
        error: {
            main: '#FF5252',  // Red for error messages
        },
        warning: {
            main: '#FFCA28',  // Yellow for warnings
        },
        info: {
            main: '#29B6F6',  // Light blue for information messages
        },
        success: {
            main: '#66BB6A',  // Green for success messages
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',  // Default font for the app
        h1: {
            fontSize: '2.5rem',
            fontWeight: 'bold',
            color: '#37474F',  // Charcoal Grey for headings
        },
        h2: {
            fontSize: '2rem',
            fontWeight: 'bold',
            color: '#37474F',
        },
        h3: {
            fontSize: '1.75rem',
            fontWeight: 'bold',
            color: '#37474F',
        },
        h4: {
            fontSize: '1.5rem',
            fontWeight: 'bold',
            color: '#37474F',
        },
        h5: {
            fontSize: '1.25rem',
            fontWeight: 'bold',
            color: '#37474F',
        },
        h6: {
            fontSize: '1rem',
            fontWeight: 'bold',
            color: '#37474F',
        },
        body1: {
            fontSize: '1rem',
            color: '#37474F',  // Main text color
        },
        body2: {
            fontSize: '0.875rem',
            color: '#607D8B',  // Secondary text color
        },
        button: {
            textTransform: 'none',  // Disable button text transformation (no uppercase)
            fontWeight: 'bold',
            fontSize: '1rem',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: '25px',  // Rounded buttons
                    padding: '10px 20px',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: '15px',  // Rounded paper edges (cards, dialogs)
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',  // Remove the default shadow from the AppBar
                },
            },
        },
    },
});

export default theme;

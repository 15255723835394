import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import getSignInSideTheme from '../theme/getSignInSideTheme';
import SignInCard from './SignInCard';
import Content from './Content';

export default function SignInSide() {
    const [mode, setMode] = React.useState('light');

    const SignInSideTheme = createTheme(getSignInSideTheme(mode));

    // This code only runs on the client side, to determine the system color preference
    React.useEffect(() => {
        // Check if there is a preferred mode in localStorage
        const savedMode = localStorage.getItem('themeMode');
        if (savedMode) {
            setMode(savedMode);
        }
    }, []);

    return (
        <ThemeProvider theme={SignInSideTheme}>
            <CssBaseline />
            <Stack
                direction="column"
                component="main"
                sx={{
                    justifyContent: 'space-between',
                    height: { xs: 'auto', md: '100%' },
                    backgroundImage: 'radial-gradient(ellipse at 70% 51%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
                    backgroundSize: 'cover',
                }}
            >
                <Stack
                    direction={{ xs: 'column-reverse', md: 'row' }}
                    sx={{
                        justifyContent: 'center',
                        gap: { xs: 6, sm: 12 },
                        p: { xs: 2, sm: 4 },
                        m: 'auto',
                    }}
                >
                    <Content />
                    <SignInCard />
                </Stack>
            </Stack>
        </ThemeProvider>
    );
}

import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice'; // Correct import path for authSlice

// Create and export the store as the default export
const store = configureStore({
    reducer: {
        auth: authReducer, // Add auth reducer to the store
    },
});

export default store;  // Change to default export

import React, { useEffect } from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './store';
import ProfilePage from './components/ProfilePage';
import HomePage from './components/HomePage';
import RegistrationForm from './components/SignUp';
import LoginPage from './components/SignInSide';
import TranslationDashboard from './components/TranslationDashboard';
import EditProject from './components/EditProject';
import TranslateProject from './components/TranslateProject';
import TranslationProjects from './components/TranslationProjects';
import NewTranslation from './components/NewTranslation';
import LanguageSettings from './components/LanguageSettings';
import AccountSettings from './components/AccountSettings';
import DashboardHome from './components/DashboardHome';
import { loginSuccess } from './slices/authSlice';
import Team from './components/TeamPage';
import GlossaryTerms from './components/GlossaryTerms'; // Import GlossaryTerms page

// Create a ProtectedRoute component to check if the user is authenticated
const ProtectedRoute = ({ element: Component }) => {
    const { isAuthenticated } = useSelector((state) => state.auth);
    return isAuthenticated ? <Component /> : <LoginPage />;
};

const App = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            dispatch(loginSuccess({ token }));
        }
    }, [dispatch]);

    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/register" element={<RegistrationForm />} />
                <Route path="/login" element={<LoginPage />} />

                {/* Protect the dashboard and its nested routes */}
                <Route path="/dashboard/*" element={<ProtectedRoute element={TranslationDashboard} />}>
                    <Route path="" element={<DashboardHome />} />
                    <Route path="projects" element={<TranslationProjects />} />
                    <Route path="new-translation" element={<NewTranslation />} />
                    <Route path="projects/:id/edit" element={<EditProject />} />
                    <Route path="projects/:id/translate" element={<TranslateProject />} />
                    <Route path="language-settings" element={<LanguageSettings />} />
                    <Route path="account-settings" element={<AccountSettings />} />
                    <Route path="profile" element={<ProfilePage />} />
                    <Route path="team" element={<Team />} />
                    <Route path="glossary/:glossaryId/terms" element={<GlossaryTerms />} /> {/* New GlossaryTerms route */}
                </Route>
            </Routes>
        </Router>
    );
};

// Wrap App in Redux Provider
const RootApp = () => (
    <Provider store={store}>
        <App />
    </Provider>
);

export default RootApp;

import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import SpeedRoundedIcon from '@mui/icons-material/SpeedRounded';
import VerifiedUserRoundedIcon from '@mui/icons-material/VerifiedUserRounded';

const translationHighlights = [
    {
        icon: <VerifiedUserRoundedIcon fontSize="large" />,
        title: '100% Accuracy with AI & Human Review',
        description:
            'Achieve top-quality translations with a blend of AI efficiency and human precision, ensuring every nuance is accurately conveyed.',
    },
    {
        icon: <SpeedRoundedIcon fontSize="large" />,
        title: 'Instant Translation Speed',
        description:
            'Benefit from real-time translation speeds, allowing you to translate documents quickly without compromising quality.',
    },
    {
        icon: <LanguageRoundedIcon fontSize="large" />,
        title: 'Over 100 Languages Supported',
        description:
            'Translate content into over 100 languages, including rare languages and dialects, to expand your global reach.',
    },
];

const generalHighlights = [
    {
        icon: <SettingsSuggestRoundedIcon />,
        title: 'Adaptable Performance',
        description:
            'Our platform effortlessly adjusts to your needs, optimizing workflow efficiency and simplifying your tasks.',
    },
    {
        icon: <ConstructionRoundedIcon />,
        title: 'Built to Last',
        description:
            'Designed for reliability, our translation system is built to handle large volumes while maintaining peak performance.',
    },
    {
        icon: <ThumbUpAltRoundedIcon />,
        title: 'Intuitive User Interface',
        description:
            'Easily navigate through features with an intuitive design, enhancing your experience at every step.',
    },
];

export default function Highlights() {
    return (
        <Box
            id="highlights"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                color: 'white',
                bgcolor: 'grey.900',
            }}
        >
            <Container
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: { xs: 3, sm: 6 },
                }}
            >
                <Box
                    sx={{
                        width: { sm: '100%', md: '60%' },
                        textAlign: { sm: 'left', md: 'center' },
                    }}
                >
                    <Typography component="h2" variant="h4" gutterBottom>
                        Highlights
                    </Typography>
                    <Typography variant="body1" sx={{ color: 'grey.400' }}>
                        Discover what makes our translation platform exceptional: unmatched accuracy, speed, and extensive language support, complemented by a reliable, user-friendly design.
                    </Typography>
                </Box>

                <Grid container spacing={2}>
                    {translationHighlights.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Stack
                                direction="column"
                                component={Card}
                                spacing={1}
                                useFlexGap
                                sx={{
                                    color: 'inherit',
                                    p: 3,
                                    height: '100%',
                                    borderColor: 'hsla(220, 25%, 25%, 0.3)',
                                    backgroundColor: 'grey.800',
                                }}
                            >
                                <Box sx={{ opacity: '80%' }}>{item.icon}</Box>
                                <div>
                                    <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'grey.400' }}>
                                        {item.description}
                                    </Typography>
                                </div>
                            </Stack>
                        </Grid>
                    ))}

                    {generalHighlights.map((item, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index + translationHighlights.length}>
                            <Stack
                                direction="column"
                                component={Card}
                                spacing={1}
                                useFlexGap
                                sx={{
                                    color: 'inherit',
                                    p: 3,
                                    height: '100%',
                                    borderColor: 'hsla(220, 25%, 25%, 0.3)',
                                    backgroundColor: 'grey.800',
                                }}
                            >
                                <Box sx={{ opacity: '80%' }}>{item.icon}</Box>
                                <div>
                                    <Typography gutterBottom sx={{ fontWeight: 'medium' }}>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" sx={{ color: 'grey.400' }}>
                                        {item.description}
                                    </Typography>
                                </div>
                            </Stack>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
}

import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Container, Button, Typography, Paper, Box, TextField, MenuItem, Select, InputLabel, FormControl, Grid, LinearProgress } from '@mui/material';
import Flag from 'react-world-flags';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import isoLanguages from './isoLanguages'; // Import the centralized language options

const NewTranslation = () => {
    const [file, setFile] = useState(null);
    const [fileNameOnServer, setFileNameOnServer] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState('');
    const [projectName, setProjectName] = useState('');
    const [sourceLanguage, setSourceLanguage] = useState('');
    const [targetLanguages, setTargetLanguages] = useState([]);
    const [deadline, setDeadline] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const token = useSelector((state) => state.auth.token);
    const navigate = useNavigate();
    const location = useLocation();

    // Pre-upload the file when selected/dropped
    const preUploadFile = useCallback(async (selectedFile) => {
        if (!selectedFile) {
            setError('Please select or drop a file.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const response = await axios.post('/api/new-translation/pre-upload', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(progress);
                }
            });

            setFileNameOnServer(response.data.fileName);
            setSuccessMessage('File uploaded successfully.');
        } catch (error) {
            console.error('Error pre-uploading file:', error);
            setError('Failed to upload file.');
        }
    }, [token]);

    // Handle file drop
    const onDrop = useCallback((acceptedFiles) => {
        setFile(acceptedFiles[0]);
        setError('');
        setSuccessMessage('');
        setUploadProgress(0);

        preUploadFile(acceptedFiles[0]);
    }, [preUploadFile]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: '.doc, .docx, .idml, .html, .pptx, .json, .xml, .xlf, .xliff',
        multiple: false,
    });

    const deleteFile = useCallback(async () => {
        if (fileNameOnServer && location.pathname !== '/new-translation/delete-file') {
            try {
                await axios.delete(`/api/new-translation/delete-file/${encodeURIComponent(fileNameOnServer)}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                });
                setFile(null);
                setFileNameOnServer('');
                setSuccessMessage('');
                setUploadProgress(0);
            } catch (error) {
                console.error('Error deleting file:', error);
                setError('Failed to delete the file.');
            }
        }
    }, [fileNameOnServer, token, location.pathname]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (fileNameOnServer && location.pathname !== '/new-translation/delete-file') {
                deleteFile();
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            if (fileNameOnServer && location.pathname !== '/new-translation/delete-file') {
                deleteFile();
            }
        };
    }, [deleteFile, fileNameOnServer, location.pathname]);

    const handleProjectCreation = async () => {
        if (!file || !projectName || !sourceLanguage || targetLanguages.length === 0) {
            setError('Please fill in all the fields and select a file.');
            return;
        }

        try {
            const adjustedTargetLanguages = targetLanguages.map((lang) =>
                lang.toLowerCase() === 'en' ? 'en-gb' : lang.toLowerCase()
            );

            const formData = new FormData();
            formData.append('file', file);
            formData.append('projectName', projectName);
            formData.append('sourceLanguage', sourceLanguage.toLowerCase());
            formData.append('targetLanguages', JSON.stringify(adjustedTargetLanguages));
            formData.append('deadline', deadline);

            await axios.post('/api/new-translation/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`,
                },
            });

            setSuccessMessage('Projects created successfully!');
            setFile(null);
            setFileNameOnServer('');
            setProjectName('');
            setSourceLanguage('');
            setTargetLanguages([]);
            setDeadline('');
            setUploadProgress(0);
        } catch (err) {
            console.error('Error uploading file:', err);
            setError('Error uploading or processing the file.');
        }
    };

    const formatFileSize = (size) => {
        if (size < 1024 * 1024) {
            return `${(size / 1024).toFixed(2)} KB`;
        } else {
            return `${(size / (1024 * 1024)).toFixed(2)} MB`;
        }
    };

    return (
        <Container maxWidth="md" sx={{ marginTop: '50px' }}>
            <Paper elevation={3} sx={{ padding: '30px', borderRadius: '10px' }}>
                <Typography variant="h4" gutterBottom>
                    Create a New Translation Project
                </Typography>

                <Box sx={{ marginBottom: '20px' }}>
                    <TextField
                        label="Project Name"
                        variant="outlined"
                        fullWidth
                        required
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                    />
                </Box>

                <Grid container spacing={2} sx={{ marginBottom: '20px' }}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>Source Language</InputLabel>
                            <Select
                                value={sourceLanguage}
                                onChange={(e) => setSourceLanguage(e.target.value)}
                                required
                            >
                                {isoLanguages.map((language) => (
                                    <MenuItem key={language.code} value={language.code}>
                                        <Flag code={language.countryCode} style={{ marginRight: '10px', width: '20px' }} />
                                        {language.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>Target Languages</InputLabel>
                            <Select
                                value={targetLanguages}
                                onChange={(e) => setTargetLanguages(e.target.value)}
                                multiple
                                required
                            >
                                {isoLanguages.map((language) => (
                                    <MenuItem key={language.code} value={language.code}>
                                        <Flag code={language.countryCode} style={{ marginRight: '10px', width: '20px' }} />
                                        {language.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Box sx={{ marginBottom: '20px', paddingTop: '20px' }}>
                    <TextField
                        label="Deadline"
                        type="date"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                        value={deadline}
                        onChange={(e) => setDeadline(e.target.value)}
                    />
                </Box>

                <Box
                    {...getRootProps()}
                    sx={{
                        border: '2px dashed gray',
                        borderRadius: '15px',
                        backgroundColor: isDragActive ? '#f0f0f0' : '#fafafa',
                        padding: '50px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        marginBottom: '20px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        transition: 'background-color 0.2s ease-in-out',
                    }}
                >
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <Typography>Drop the file here...</Typography>
                    ) : (
                        <Typography>
                            {file ? `Selected File: ${file.name}` : 'Drag and drop a .docx, .pptx, html, .json, .xml, .xlf, .xliff  or .idml file here, or click to select'}
                        </Typography>
                    )}
                </Box>

                {file && (
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                        <Box>
                            <Typography variant="body1">File Name: {file.name}</Typography>
                            <Typography variant="body2">File Size: {formatFileSize(file.size)}</Typography>
                            <LinearProgress variant="determinate" value={uploadProgress} />
                        </Box>
                    </Box>
                )}

                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleProjectCreation}
                    fullWidth
                    sx={{ marginTop: '20px', padding: '10px 0', fontSize: '16px' }}
                    disabled={uploadProgress !== 100}
                >
                    Create Projects
                </Button>

                {error && <Typography color="error" sx={{ marginTop: '20px' }}>{error}</Typography>}
                {successMessage && <Typography color="primary" sx={{ marginTop: '20px' }}>{successMessage}</Typography>}
            </Paper>
        </Container>
    );
};

export default NewTranslation;
